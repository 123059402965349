import { AnnotationCategories, MediaAnnotation } from "../../store/MediaStore";

export const fakeAnnotations: MediaAnnotation[] = [
    {
        id: 1,
        answerData: {
            instances: [
                {
                    parentInstance: 0,
                    keypoints: [
                        {
                            string: "Nose",
                            pixelX: 1337.2076941483858,
                            pixelY: 205.64623700072434,
                            visible: true,
                        },
                        {
                            string: "Left Eye",
                            pixelX: 1347.9249663700332,
                            pixelY: 202.82595198675497,
                            visible: true,
                        },
                        {
                            string: "Right Eye",
                            pixelX: 1338.61801854822,
                            pixelY: 198.313481412976,
                            visible: true,
                        },
                        {
                            string: "Left Ear",
                            pixelX: 1360.6162853114652,
                            pixelY: 210.44073243804326,
                            visible: true,
                        },
                        {
                            string: "Right Ear",
                            pixelX: 1335.515654102856,
                            pixelY: 211.568831892203,
                            visible: false,
                        },
                        {
                            string: "Neck",
                            pixelX: 1336.3618196399007,
                            pixelY: 221.72187249392073,
                            visible: true,
                        },
                        {
                            string: "Left Shoulder",
                            pixelX: 1356.1037419805464,
                            pixelY: 246.54040608185016,
                            visible: true,
                        },
                        {
                            string: "Right Shoulder",
                            pixelX: 1318.8759506932947,
                            pixelY: 209.31261479459852,
                            visible: true,
                        },
                        {
                            string: "Left Elbow",
                            pixelX: 1299.134028352649,
                            pixelY: 270.7948899426997,
                            visible: true,
                        },
                        {
                            string: "Right Elbow",
                            pixelX: 1304.7745983805878,
                            pixelY: 236.38738366941743,
                            visible: false,
                        },
                        {
                            string: "Left Wrist",
                            pixelX: 1284.4685171771523,
                            pixelY: 226.51636793123964,
                            visible: true,
                        },
                        {
                            string: "Right Wrist",
                            pixelX: 1305.9026250776076,
                            pixelY: 229.05462808166908,
                            visible: false,
                        },
                        {
                            string: "Left Thumb",
                            pixelX: 1293.7754649989652,
                            pixelY: 210.7227573015832,
                            visible: true,
                        },
                        {
                            string: "Right Thumb",
                            pixelX: 1298.2878628156043,
                            pixelY: 185.90422371365375,
                            visible: true,
                        },
                        {
                            string: "Left Pinky",
                            pixelX: 1287.8528882967714,
                            pixelY: 192.67291138503725,
                            visible: true,
                        },
                        {
                            string: "Right Pinky",
                            pixelX: 1290.673100553601,
                            pixelY: 185.3401557972889,
                            visible: true,
                        },
                        {
                            string: "Left Hip",
                            pixelX: 1307.594956151697,
                            pixelY: 306.89459996507657,
                            visible: true,
                        },
                        {
                            string: "Right Hip",
                            pixelX: 1260.2141970198675,
                            pixelY: 269.66677229925494,
                            visible: true,
                        },
                        {
                            string: "Left Knee",
                            pixelX: 1276.5718937551737,
                            pixelY: 358.505859375,
                            visible: true,
                        },
                        {
                            string: "Right Knee",
                            pixelX: 1232.5753602286838,
                            pixelY: 321.2780317091784,
                            visible: true,
                        },
                        {
                            string: "Left Ankle",
                            pixelX: 1257.6758459230132,
                            pixelY: 401.65622736444533,
                            visible: true,
                        },
                        {
                            string: "Right Ankle",
                            pixelX: 1209.1668418227441,
                            pixelY: 372.60728444484687,
                            visible: true,
                        },
                        {
                            string: "Left Heel",
                            pixelX: 1261.0600715283526,
                            pixelY: 413.5014535259727,
                            visible: true,
                        },
                        {
                            string: "Right Heel",
                            pixelX: 1215.6535046305878,
                            pixelY: 383.0423317208195,
                            visible: true,
                        },
                        {
                            string: "Left BigToe",
                            pixelX: 1229.1908435947848,
                            pixelY: 425.62868636175494,
                            visible: true,
                        },
                        {
                            string: "Right BigToe",
                            pixelX: 1191.9630523075332,
                            pixelY: 397.14379316923635,
                            visible: true,
                        },
                        {
                            string: "Left SmallToe",
                            pixelX: 1240.7539903249171,
                            pixelY: 432.6793988966784,
                            visible: true,
                        },
                        {
                            string: "Right SmallToe",
                            pixelX: 1183.502197265625,
                            pixelY: 392.0672728683775,
                            visible: true,
                        },
                    ],
                },
                {
                    parentInstance: 1,
                    keypoints: [
                        {
                            string: "Nose",
                            pixelX: 256.07677535505485,
                            pixelY: 180.94091352248034,
                            visible: false,
                        },
                        {
                            string: "Left Eye",
                            pixelX: 256.07677535505485,
                            pixelY: 180.94091352248034,
                            visible: false,
                        },
                        {
                            string: "Right Eye",
                            pixelX: 256.07677535505485,
                            pixelY: 180.94091352248034,
                            visible: false,
                        },
                        {
                            string: "Left Ear",
                            pixelX: 256.07677535505485,
                            pixelY: 180.94091352248034,
                            visible: false,
                        },
                        {
                            string: "Right Ear",
                            pixelX: 256.07677535505485,
                            pixelY: 180.94091352248034,
                            visible: false,
                        },
                        {
                            string: "Neck",
                            pixelX: 236.56500203719992,
                            pixelY: 177.8802389182792,
                            visible: true,
                        },
                        {
                            string: "Left Shoulder",
                            pixelX: 205.19312372270798,
                            pixelY: 162.959477506726,
                            visible: true,
                        },
                        {
                            string: "Right Shoulder",
                            pixelX: 271.76272360694327,
                            pixelY: 214.9908730210058,
                            visible: true,
                        },
                        {
                            string: "Left Elbow",
                            pixelX: 236.18242680631727,
                            pixelY: 237.18074571217923,
                            visible: false,
                        },
                        {
                            string: "Right Elbow",
                            pixelX: 278.64922327711093,
                            pixelY: 258.60543156301736,
                            visible: true,
                        },
                        {
                            string: "Left Wrist",
                            pixelX: 247.277344962619,
                            pixelY: 271.2307052107047,
                            visible: false,
                        },
                        {
                            string: "Right Wrist",
                            pixelX: 293.95257810883174,
                            pixelY: 210.39987930398902,
                            visible: true,
                        },
                        {
                            string: "Left Thumb",
                            pixelX: 204.4279550716577,
                            pixelY: 259.7531936342353,
                            visible: false,
                        },
                        {
                            string: "Right Thumb",
                            pixelX: 206.72346102480856,
                            pixelY: 259.37061840335264,
                            visible: false,
                        },
                        {
                            string: "Left Pinky",
                            pixelX: 206.72346102480856,
                            pixelY: 259.37061840335264,
                            visible: false,
                        },
                        {
                            string: "Right Pinky",
                            pixelX: 206.72346102480856,
                            pixelY: 259.37061840335264,
                            visible: false,
                        },
                        {
                            string: "Left Hip",
                            pixelX: 196.39369333027213,
                            pixelY: 250.95378143108442,
                            visible: true,
                        },
                        {
                            string: "Right Hip",
                            pixelX: 234.26949608404905,
                            pixelY: 285.7689095806602,
                            visible: true,
                        },
                        {
                            string: "Left Knee",
                            pixelX: 174.58641405926633,
                            pixelY: 307.57618885166596,
                            visible: true,
                        },
                        {
                            string: "Right Knee",
                            pixelX: 203.28019300043977,
                            pixelY: 335.1222239109064,
                            visible: true,
                        },
                        {
                            string: "Left Ankle",
                            pixelX: 137.85835518742238,
                            pixelY: 357.6946900222475,
                            visible: true,
                        },
                        {
                            string: "Right Ankle",
                            pixelX: 169.2302335019143,
                            pixelY: 392.89237521342096,
                            visible: true,
                        },
                        {
                            string: "Left Heel",
                            pixelX: 124.4679219833273,
                            pixelY: 371.08509594241514,
                            visible: true,
                        },
                        {
                            string: "Right Heel",
                            pixelX: 153.54430343931085,
                            pixelY: 403.9873297482926,
                            visible: true,
                        },
                        {
                            string: "Left BigToe",
                            pixelX: 166.16955889771316,
                            pixelY: 364.96378311258275,
                            visible: true,
                        },
                        {
                            string: "Right BigToe",
                            pixelX: 195.24593125905423,
                            pixelY: 394.04015547392385,
                            visible: true,
                        },
                        {
                            string: "Left SmallToe",
                            pixelX: 162.72630906262935,
                            pixelY: 358.07724706384516,
                            visible: true,
                        },
                        {
                            string: "Right SmallToe",
                            pixelX: 196.01109991010452,
                            pixelY: 404.3699231684603,
                            visible: true,
                        },
                    ],
                },
                {
                    parentInstance: 1,
                    keypoints: [
                        {
                            string: "Nose",
                            pixelX: 1000.7267306498344,
                            pixelY: 286.52903979977236,
                            visible: true,
                        },
                        {
                            string: "Left Eye",
                            pixelX: 1006.8433506441432,
                            pixelY: 288.3193929305929,
                            visible: true,
                        },
                        {
                            string: "Right Eye",
                            pixelX: 1003.7410589559189,
                            pixelY: 281.83273012274935,
                            visible: true,
                        },
                        {
                            string: "Left Ear",
                            pixelX: 1022.0729479252897,
                            pixelY: 297.3443158888659,
                            visible: true,
                        },
                        {
                            string: "Right Ear",
                            pixelX: 1017.8424840257657,
                            pixelY: 311.44574095871275,
                            visible: false,
                        },
                        {
                            string: "Neck",
                            pixelX: 1031.3798957471026,
                            pixelY: 304.6770532873293,
                            visible: true,
                        },
                        {
                            string: "Left Shoulder",
                            pixelX: 1041.5329363488204,
                            pixelY: 337.3923921900869,
                            visible: true,
                        },
                        {
                            string: "Right Shoulder",
                            pixelX: 1026.0213323934188,
                            pixelY: 336.26429273592714,
                            visible: false,
                        },
                        {
                            string: "Left Elbow",
                            pixelX: 1035.8922935637418,
                            pixelY: 394.08020827943915,
                            visible: true,
                        },
                        {
                            string: "Right Elbow",
                            pixelX: 984.5630772066432,
                            pixelY: 341.62281971104096,
                            visible: true,
                        },
                        {
                            string: "Left Wrist",
                            pixelX: 1012.201913997827,
                            pixelY: 424.8213367588473,
                            visible: true,
                        },
                        {
                            string: "Right Wrist",
                            pixelX: 973.2819371507657,
                            pixelY: 367.2874278895902,
                            visible: true,
                        },
                        {
                            string: "Left Thumb",
                            pixelX: 1000.3566878362997,
                            pixelY: 429.33377095405626,
                            visible: true,
                        },
                        {
                            string: "Right Thumb",
                            pixelX: 970.4616521367964,
                            pixelY: 379.69670377819745,
                            visible: true,
                        },
                        {
                            string: "Left Pinky",
                            pixelX: 1007.689443424048,
                            pixelY: 439.486811555774,
                            visible: true,
                        },
                        {
                            string: "Right Pinky",
                            pixelX: 976.102222164735,
                            pixelY: 376.03032598432327,
                            visible: true,
                        },
                        {
                            string: "Left Hip",
                            pixelX: 1012.201913997827,
                            pixelY: 397.74654969474335,
                            visible: true,
                        },
                        {
                            string: "Right Hip",
                            pixelX: 979.7685999586092,
                            pixelY: 371.51789178911423,
                            visible: true,
                        },
                        {
                            string: "Left Knee",
                            pixelX: 980.3326133071192,
                            pixelY: 431.5900062409458,
                            visible: true,
                        },
                        {
                            string: "Right Knee",
                            pixelX: 953.821948727235,
                            pixelY: 417.206538118274,
                            visible: true,
                        },
                        {
                            string: "Left Ankle",
                            pixelX: 1007.4073639926531,
                            pixelY: 477.56069562293044,
                            visible: true,
                        },
                        {
                            string: "Right Ankle",
                            pixelX: 930.6955097526903,
                            pixelY: 481.79115952245445,
                            visible: true,
                        },
                        {
                            string: "Left Heel",
                            pixelX: 1018.1244179428808,
                            pixelY: 479.53496061413495,
                            visible: true,
                        },
                        {
                            string: "Right Heel",
                            pixelX: 932.669701986755,
                            pixelY: 493.0723359569019,
                            visible: true,
                        },
                        {
                            string: "Left BigToe",
                            pixelX: 983.7169116695985,
                            pixelY: 499.27699209049047,
                            visible: true,
                        },
                        {
                            string: "Right BigToe",
                            pixelX: 897.9801890392177,
                            pixelY: 478.6888678342301,
                            visible: true,
                        },
                        {
                            string: "Left SmallToe",
                            pixelX: 993.5879455970612,
                            pixelY: 505.76365489833404,
                            visible: true,
                        },
                        {
                            string: "Right SmallToe",
                            pixelX: 901.6465668330919,
                            pixelY: 473.8943905861962,
                            visible: true,
                        },
                    ],
                },
            ],
        },
        mediaId: 22,
        annotationType: AnnotationCategories.Keypoints,
        timestep: 0,
        completionTime: 30,
        verified: false,
        user: {},
        isLatest: true,
        isPartial: false,
        comment: "",
        discarded: false,
        state: "NeedsVerification",
    },
    {
        id: 2,
        answerData: {
            instances: [
                {
                    label: "ball",
                    points: [
                        {
                            pixelX: 339.69924812030075,
                            pixelY: 1048.7218045112782,
                        },
                        {
                            pixelX: 385.4135338345865,
                            pixelY: 1092.03007518797,
                        },
                    ],
                    shape_type: "rectangle",
                },
                {
                    label: "crowd",
                    points: [
                        {
                            pixelX: 2.563520328588948,
                            pixelY: 889.8131006719783,
                        },
                        {
                            pixelX: 3169.7333489640696,
                            pixelY: 911.9768480477354,
                        },
                        {
                            pixelX: 4033.2359280355176,
                            pixelY: 2155.7589869562225,
                        },
                        {
                            pixelX: 3.070633810522273,
                            pixelY: 2212.7341652756745,
                        },
                        {
                            pixelX: 1.5347753432353688,
                            pixelY: 2624.7795152034005,
                        },
                        {
                            pixelX: 4093.9329548974397,
                            pixelY: 2638.6340977336868,
                        },
                        {
                            pixelX: 4095.346593646751,
                            pixelY: 1.232258449041896,
                        },
                        {
                            pixelX: 1.5035439377839346,
                            pixelY: 2.2447576134215366,
                        },
                    ],
                    shape_type: "polygon",
                },
                {
                    label: "person",
                    instanceId: 1,
                    points: [
                        {
                            pixelX: 289.4655148678414,
                            pixelY: 866.2586067636632,
                            obscured: false,
                            visible: true,
                        },
                        {
                            pixelX: 476.6380908310676,
                            pixelY: 1199.3914687370939,
                            obscured: false,
                            visible: true,
                        },
                        {
                            pixelX: 342.5669064290723,
                            pixelY: 1041.5003726976033,
                            obscured: false,
                            visible: true,
                        },
                    ],
                    shape_type: "centeredRectangle",
                },
                {
                    label: "person",
                    instanceId: 0,
                    points: [
                        {
                            pixelX: 613.1825773936537,
                            pixelY: 612.5251803629199,
                            obscured: false,
                            visible: true,
                        },
                        {
                            pixelX: 704.6735398884912,
                            pixelY: 936.7799437938808,
                            obscured: false,
                            visible: true,
                        },
                        {
                            pixelX: 671.5942603375943,
                            pixelY: 757.7642931497044,
                            obscured: false,
                            visible: true,
                        },
                    ],
                    shape_type: "centeredRectangle",
                },
            ],
        },
        mediaId: 22,
        annotationType: AnnotationCategories.ObjectDetection,
        timestep: 0,
        completionTime: 300,
        verified: false,
        user: {},
        isLatest: true,
        isPartial: false,
        comment: "",
        discarded: false,
        state: "NeedsVerification",
    },
];
