import { inject, observer } from "mobx-react";
import React from "react";
import { ProvidedAppStore } from "../../store/AppStore";
import {
    AnnotationCategories,
    Media,
    mediaIsImage,
    MediaKeypointInstance,
    MediaObjectDetectionInstance,
    MediaStore,
    TopLevelAnnotationTypes,
} from "../../store/MediaStore";
import {
    Accordion,
    Button,
    Container,
    Dimmer,
    DimmerDimmable,
    Divider,
    Grid,
    Header,
    Icon,
    Image,
    Loader,
    Popup,
    Segment,
} from "semantic-ui-react";
import { RouteComponentProps } from "react-router-dom";
import { fakeAnnotations } from "./fakeAnnotations";
import { objectColor, ObjectDetectionDescription } from "../../models/model_types/object_detection";
import {
    getFrameFromImage,
    getFramesFromVideoTrack,
    getVideoTrack,
} from "../helpers/MediaFunctions";
import { Image as ObjectDetectionAnnotationCanvas } from "../annotation/ObjectDetection/Image";

type Props = ProvidedAppStore & RouteComponentProps;

interface MediaAnnotationPageState {
    media?: Media;
    activeAccordionIndicies: Record<TopLevelAnnotationTypes, number>;
    timestep: number;
    frames: ImageBitmap[];
    currentlyPlaying: boolean;
}

@inject("store")
@observer
export class MediaAnnotationPage extends React.Component<Props, MediaAnnotationPageState> {
    store: MediaStore | undefined;
    private playerInterval?: NodeJS.Timeout;
    private previewElement?: HTMLVideoElement;

    constructor(props: Props) {
        super(props);
        this.store = props.store?.mediaStore;
        this.state = {
            media: undefined,
            activeAccordionIndicies: { ObjectDetection: -1 },
            timestep: 0,
            frames: [],
            currentlyPlaying: false,
        };
    }

    async componentDidMount() {
        if (!this.store) return;
        this.store.fetchAnnotationTypes();
        const mediaId = this.fetchRouteParameters().id;
        this.loadMedia(mediaId).then(() => this.loadFrames());
    }

    fetchRouteParameters() {
        const { params } = this.props.match;
        const { id } = params as any;
        return { id: parseInt(id) };
    }

    async loadMedia(id: number) {
        if (!this.store || !id) return;

        const media: Media = await this.store.getMedia(id);
        this.setState({
            media: { ...media, annotations: fakeAnnotations },
        });
    }

    async loadFrames() {
        if (!this.store || !this.state.media) return;
        const media = this.state.media;

        let frames: ImageBitmap[] = [];
        const mediaUrl = this.store.getMediaUrl(media.bucketPath);
        if (mediaIsImage(media)) {
            frames = [await getFrameFromImage(mediaUrl)];
        } else {
            // note(Alex.Shaw): The frame extraction method we use requires
            // us to play the whole video, so we pass in a preview element
            // so that the user has a chance to watch the video instead of
            // waiting for a loading screen. We skip this for images, as those
            // are near instant to process.
            const track = await getVideoTrack(mediaUrl, this.previewElement!);
            frames = await getFramesFromVideoTrack(track);
        }

        this.setState({
            frames: frames,
        });
    }

    handlePlayPause() {
        const desiredState = !this.state.currentlyPlaying;
        this.setState({ currentlyPlaying: desiredState });

        if (desiredState || !this.playerInterval) {
            this.playerInterval = setInterval(() => {
                const nextFrameIndex = (this.state.timestep + 1) % this.state.frames.length;
                this.setTimestep(nextFrameIndex);
            }, 1000 / this.state.media!.fps!);
        } else {
            clearInterval(this.playerInterval);
        }
    }

    setTimestep(timestep: number) {
        this.setState({ timestep: timestep });
    }

    getInstancesForAnnotationType(annotationType: AnnotationCategories) {
        return this.state.media?.annotations
            .filter((ann) => ann.annotationType === annotationType)
            .map((ann) => ann.answerData.instances as any)
            .reduce((accumulator, value) => accumulator.concat(value), []);
    }

    // TODO: Add view/selection logic, new annotation logic and delete logic
    generateInstanceCard(key: React.Key, label: string, childCategory?: AnnotationCategories) {
        return (
            <Container
                key={key}
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
                className="media-annotation-hierarchy-instance"
            >
                {label}
                {childCategory && <Icon name="dropdown" />}
                <Container style={{ display: "flex", justifyContent: "flex-end" }}>
                    {childCategory && (
                        <Popup
                            trigger={<Button positive icon="add" />}
                            on="hover"
                            content={`Add ${childCategory}`}
                        />
                    )}
                    <Button negative icon="delete" />
                    <Button icon="eye" />
                </Container>
            </Container>
        );
    }

    generateObjectDetectionJsx() {
        if (!this.state.media) return <Loader active />;
        if (!this.store?.annotationTypes || this.store.annotationTypes.length === 0)
            return <Loader active />;

        const objectInstances = this.getInstancesForAnnotationType(
            AnnotationCategories.ObjectDetection
        ) as MediaObjectDetectionInstance[];

        const accordionJsx = objectInstances.map((objectInstance, index) => {
            if (objectInstance.label != "person")
                return this.generateInstanceCard(index, objectInstance.label);

            // note(Alex.Shaw): Person annotations may have keypoints associated
            // with them, so we return an accordion instead of a single instance here.
            const keypointInstances = (
                this.getInstancesForAnnotationType(
                    AnnotationCategories.Keypoints
                ) as MediaKeypointInstance[]
            ).filter(
                (keypointInstance) => keypointInstance.parentInstance === objectInstance.instanceId
            );

            return (
                <Accordion key={index}>
                    <Accordion.Title
                        style={{ padding: 0 }}
                        active={
                            this.state.activeAccordionIndicies[
                                AnnotationCategories.ObjectDetection
                            ] === index
                        }
                        onClick={() => {
                            this.setState({
                                activeAccordionIndicies: {
                                    ...this.state.activeAccordionIndicies,
                                    ObjectDetection: index,
                                },
                            });
                        }}
                    >
                        {this.generateInstanceCard(
                            index,
                            objectInstance.label,
                            AnnotationCategories.Keypoints
                        )}
                    </Accordion.Title>
                    <Accordion.Content
                        active={
                            this.state.activeAccordionIndicies[
                                AnnotationCategories.ObjectDetection
                            ] === index
                        }
                        style={{ marginLeft: "16px" }}
                    >
                        <Header as="h4">Keypoints</Header>
                        {keypointInstances.map((keypointInstance, kpIndex) =>
                            this.generateInstanceCard(`${index}_${kpIndex}`, "Keypoints")
                        )}
                    </Accordion.Content>
                </Accordion>
            );
        });

        const annotationType = this.store.annotationTypes.filter(
            (type) =>
                type.category === AnnotationCategories.ObjectDetection && type.type === "Generic"
        )[0];

        const newInstanceButtonsJsx = (
            annotationType.definition as ObjectDetectionDescription[]
        ).map((object) => {
            return (
                <Button
                    key={object.label}
                    color={objectColor(object)}
                    content={object.label}
                    style={{ marginBottom: "4px" }}
                />
            );
        });

        return (
            <>
                <Header as="h4">Object Detection:</Header>
                {objectInstances.length > 0 ? (
                    accordionJsx
                ) : (
                    <Header sub>No Object Annotations</Header>
                )}
                <Header sub>Add new instance:</Header>
                {newInstanceButtonsJsx}
                <Divider />
            </>
        );
    }

    generateMediaControls() {
        return (
            <Container
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "8px",
                }}
            >
                {this.state.frames.length === 0 ? (
                    <Header as="h3">Extracting frames...</Header>
                ) : (
                    <>
                        <Button
                            toggle
                            active={this.state.currentlyPlaying}
                            labelPosition="left"
                            icon={this.state.currentlyPlaying ? "pause" : "play"}
                            content={
                                this.state.currentlyPlaying
                                    ? "Pause"
                                    : `Play (${this.state.media?.fps} fps)`
                            }
                            disabled={this.state.frames.length === 0}
                            onClick={() => this.handlePlayPause()}
                        />
                        <Button
                            labelPosition="left"
                            icon="left chevron"
                            content="Previous Frame"
                            disabled={this.state.timestep < 1}
                            onClick={() => this.setTimestep(this.state.timestep - 1)}
                        />
                        <Button
                            labelPosition="right"
                            icon="right chevron"
                            content="Next Frame"
                            disabled={this.state.timestep >= this.state.frames.length}
                            onClick={() => this.setTimestep(this.state.timestep + 1)}
                        />
                    </>
                )}
            </Container>
        );
    }

    render() {
        if (!this.state.media) return <Loader active />;
        const media = this.state.media;

        return (
            <Container>
                <Header as="h2">Annotating Media {media?.id}</Header>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <DimmerDimmable as={Container} dimmed={this.state.frames.length === 0}>
                                <Dimmer inverted active={this.state.frames.length === 0} />
                                <Header as="h3">Existing Annotations</Header>
                                {this.generateObjectDetectionJsx()}
                            </DimmerDimmable>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            {!mediaIsImage(media) && this.generateMediaControls()}
                            {!mediaIsImage(media) && this.state.frames.length === 0 && (
                                <video
                                    className="media-view"
                                    ref={(child: HTMLVideoElement) => (this.previewElement = child)}
                                />
                            )}
                            {this.state.frames.length > 0 && (
                                <Container style={{ position: "relative" }}>
                                    <ObjectDetectionAnnotationCanvas
                                        image={this.state.frames[this.state.timestep]}
                                        groups={[]}
                                    />
                                </Container>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }
}
