export async function getFrameFromImage(url: string): Promise<ImageBitmap> {
    let image = new Image();
    image.src = url;
    await image.decode();
    return await createImageBitmap(image);
}

export async function getFramesFromVideoTrack(
    track: MediaStreamVideoTrack
): Promise<ImageBitmap[]> {
    const processor = new MediaStreamTrackProcessor({ track: track });
    const reader = processor.readable.getReader();

    let frames: ImageBitmap[] = [];
    let finished: boolean = false;
    while (!finished) {
        await reader.read().then(async ({ done, value }) => {
            if (value) {
                const bitmap = await createImageBitmap(value);
                frames.push(bitmap);
                value.close();
            }
            if (done) {
                finished = true;
            }
        });
    }
    return frames;
}

// note(Alex.Shaw): This is currently unavailable in Typescript, but is
// supported by all modern browsers (except Safari)
// https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/captureStream
interface HTMLVideoElementWithCaptureStream extends HTMLVideoElement {
    captureStream(): MediaStream;
}

export async function getVideoTrack(url: string, previewElement: HTMLVideoElement) {
    const video = previewElement as HTMLVideoElementWithCaptureStream;
    video.crossOrigin = "anonymous";
    video.muted = true;
    video.src = url;
    await video.play();
    const [track] = video.captureStream().getVideoTracks();
    video.onended = (evt) => track.stop();
    return track;
}
